import { createApp } from 'vue';
import App from './App.vue';
import router from './router/index'
import { TUIComponents, TUICore } from "./TUIKit";
import { TUICallKit } from './TUIKit/TUIPlugin/TUICallKit';
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
// logicflow
import '@logicflow/core/dist/style/index.css'
import '@logicflow/extension/lib/style/index.css'
import tim from './im/tim';

import globalVariable from './utils/globalVariable'
import common from './utils/common'

const SDKAppID = process.env.VUE_APP_IM_SDKAppID; // Your SDKAppID
// init TUIKit
const TUIKit = TUICore.init({
  SDKAppID,
  tim,
});
// TUIKit add TUIComponents
TUIKit.use(TUIComponents);
TUIKit.use(TUICallKit);

const app = createApp(App);

app.config.globalProperties.$TUIKit = TUIKit;

// 关闭长按左键弹出右键菜单
window.oncontextmenu = function (event: any) {
  const pointerEvent = event as PointerEvent;
  // eslint-disable-next-line no-console
  console.log(`window.oncontextmenu: ${pointerEvent.pointerType}`);

  if (pointerEvent.pointerType === 'touch') {
    // context menu was triggerd by long press
    return false;
  }

  // just to show that pointerEvent.pointerType has another value 'mouse' aka right click
  if (pointerEvent.pointerType === 'mouse') {
    // context menu was triggered by right click
    return true;
  }

  // returning true will show a context menu for other cases
  return true;
};

app.config.globalProperties.$common = common;
app.config.globalProperties.globalVariable = globalVariable;
app.use(router).use(ElementPlus).use(TUIKit);
app.mount("#app");
