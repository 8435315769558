import axios from 'axios'
import { ElMessage } from 'element-plus'

import router from '@/router'

//指定请求数据格式与编码
axios.defaults.headers.post["Content-Type"] = "application/json;charset=UTF-8";
let userToken = window.sessionStorage.getItem("HCZH-TOKEN")

if (userToken) {
   axios.defaults.headers.post['token'] = userToken
}


const service = axios.create({
    baseURL: process.env.VUE_APP_BASE_URL, // url = base url + request url
    timeout: 50 * 1000 // request timeout
})

// http request 拦截器
service.interceptors.request.use(
    config => {
        config.headers['token'] = window.sessionStorage.getItem("HCZH-TOKEN")
        return config
    },
    error => {
        console.log("拦截error----", error) // for debug
        return Promise.reject(error)
    }
)

// 设置请求次数，请求的间隙
service.defaults.retry = 4;
service.defaults.retryDelay = 1000;

// response interceptor
service.interceptors.response.use(
    response => {
        const res = response.data
        if (res.code !== 0) {
            if (res.code == 401) {
                ElMessage({
                    message: res.error,
                    type: 'error',
                    duration: 5 * 1000
                })
                //token过期自动跳转到登录页面，重新登录
                router.push({ path: "/login" });
            } else if (res.code === -1) {
                ElMessage({
                    message: res.error,
                    type: 'error',
                    duration: 5 * 1000
                })
            }
        } else {
            return res
        }
    },
    error => {
        console.log('请求erro----', error) // for debug
        ElMessage({
            message: error.message,
            type: 'error',
            duration: 5 * 1000
        })
        return Promise.reject(error)
    },
)

export default service
