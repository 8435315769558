import request from "@/request/http";

//登录
export function userLogin(data: any) {
  return request({
    url: "/companions/companion/loginCompanionByPwd",
    method: "post",
    data,
  });
}

// 获取学伴详细信息
export function userInfo(data: any) {
  return request({
    url: "/companions/companion/detailsByToken",
    method: "POST",
    data,
  });
}

// 学伴修改头像
export function updateHeadImg(data: any) {
  return request({
    url: "/base/user/updateByToken",
    method: "POST",
    data,
  });
}

// 学伴退出登录
export function loginOutByCompanion(data: any) {
  return request({
    url: "/companions/companion/loginOutByCompanion",
    method: "POST",
    data,
  });
}

// 已结束订单
export function getEndOrderList(data: any) {
  return request({
    // url: "/companions/accompanyStudyOrder/getEndOrderList",
    url: "/companions/accompanyStudyOrder/v2/getEndOrderList",
    method: "POST",
    data,
  });
}

// 评价订单
export function evaluateStudent(data: any) {
  return request({
    url: "/companions/evaluationStudent/createV2",
    method: "POST",
    data,
  });
}

// 学习中/伴学中订单
// export function matchingList(data: any) {
//   return request({
//     url: "/students/studyOrder/getMatchingList",
//     method: "POST",
//     data,
//   });
// }
// 学习中/伴学中订单 新接口 
export function matchingList(data: any) {
  return request({
    url: "/companions/companyMatching/getMatch",
    method: "GET",
    data,
  });
}

//查询学生当天学习情况数据
export function studentToday() {
  return request({
    url: "/students/mission/getStudentTodayStudyStatusByCompanionToken",
    method: "POST"
  });
}


// 学伴接单
export function orderTaking(data: any) {
  return request({
    url: "/students/studyOrder/orderTaking",
    method: "POST",
    data,
  });
}

// 学伴首次去答疑
export function updateAnswerTime(data: any) {
  return request({
    url: "/companions/accompanyStudyOrder/updateAnswerTime",
    method: "POST",
    data,
  });
}

// 消除红点
export function updateStudyStatus(data: any) {
  return request({
    url: "/companions/companion/updateStudyStatus",
    method: "POST",
    data,
  });
}

// 通过/不通过答疑
export function accompanyStudyOrderEnd(data: any) {
  return request({
    url: "/students/studyOrder/end",
    method: "POST",
    data,
  });
}

// 根据type获取字典数据
export function notifyTypeList(data: any) {
  return request({
    url: "/base/dict/getListByType",
    method: "POST",
    data,
  });
}

// 获取学生今日学习情况通知列表
export function notifyList(data: any) {
  return request({
    url: "/companions/companionNotifyParent/getListByStudentId",
    method: "POST",
    data,
  });
}

// 新增学习通知
export function notifyAdd(data: any) {
  return request({
    url: "/companions/companionNotifyParent/create",
    method: "POST",
    data,
  });
}

// 获取腾讯IM令牌
export function getTimSig(data: any) {
  return request({
    url: "/base/tim/sig",
    method: "POST",
    data,
  });
}

// 腾讯语音转文字
export function speechToText(data: any) {
  return request({
    url: "/fastdfs/tencentApi/sentenceRecognition",
    method: "POST",
    data,
  });
}

// 根据学生 id 更新资源标记
export function updateCompanionStatus(data: any) {
  return request({
    url: "/students/planResources/updateResourceByStudentId",
    method: "POST",
    data,
  });
}

// 根据练习ID获取话术
export function getDialogueLogic(type: number, data: any) {
  return request({
    url: type === 1 ? "/subject/bookKnowledge/getDialogueLogic" : "/subject/bookPractice/getDialogueLogic",
    method: "POST",
    data,
  });
}

// 伴学话术-主题列表
export function chatTopicList(data: any) {
  return request({
    url: "/companions/chatTopic/list",
    method: "POST",
    data,
  });
}

// 伴学话术-标签下拉列表
export function chatTagList(data: any) {
  return request({
    url: "/companions/chatTag/list",
    method: "POST",
    data,
  });
}

// 伴学话术-话术列表
export function chatMessageList(data: any) {
  return request({
    url: "/companions/chatMessage/page2",
    method: "POST",
    data,
  });
}

// 伴学话术-增加复制次数
export function addCopyNum(data: any) {
  return request({
    url: "/companions/cmcRecord/create",
    method: "POST",
    data,
  });
}

// 伴学话术-标记
export function updateSpeechFlag(data: any) {
  return request({
    url: "/companions/chatMessage/flag",
    method: "POST",
    data,
  });
}

// 保存表情包
export function userEmojiCreate(data: any) {
  return request({
    url: "/students/userEmoji/create",
    method: "POST",
    data,
  });
}

// 查询用户所有表情包
export function userEmojiList(data: any) {
  return request({
    url: "/students/userEmoji/selectListByUser",
    method: "POST",
    data,
  });
}

// 查询用户所有表情包
export function userEmojiDel(data: any) {
  return request({
    url: "/students/userEmoji/delete",
    method: "POST",
    data,
  });
}

// 上传网络表情包
export function addEmoji(data: any) {
  return request({
    url: "/fastdfs/addEmoji",
    method: "POST",
    data,
  });
}

// 根据会话id获取语音已读列表
export function voiceReadList(data: any) {
  return request({
    url: "/tim/tencentIm/getVoiceReadList",
    method: "POST",
    data,
  });
}

// 根据会话id获取语音已读列表
export function setVoiceRead(data: any) {
  return request({
    url: "/tim/tencentIm/setVoiceRead",
    method: "POST",
    data,
  });
}

// 添加练习讲题话术反馈
export function addFlowFeedback(data: any) {
  return request({
    url: "/subject/logicFlowFeedback/create",
    method: "POST",
    data,
  });
}

// 添加问题讲题话术反馈
export function addFlowFeedback2(data: any) {
  return request({
    url: "/subject/knowledgeLogicFlowFeedback/create",
    method: "POST",
    data,
  });
}

// 练习讲题话术复制
export function copyFlowFeedback(data: any, type: number) {
  return request({
    url: type === 1 ? "/subject/lfckRecord/create" : "/subject/lfcRecord/create",
    method: "POST",
    data,
  });
}

// 获取学生
export function  getLastHistoryByStudId(data: any){
  return request({
    url: "/base/flowHistory/getLastHistoryByStudId",
    method: "POST",
    data,
  });
} 