import { createRouter, createWebHashHistory } from "vue-router";

const routes = [
  {
    path: "/",
    redirect: "/login",
  },
  {
    path: "/login",
    component: () => import("@/view/Login.vue"),
  },
  {
    path: "/home",
    component: () => import("@/view/Home.vue"),
  },
];

const router = new createRouter({
  routes,
  history: createWebHashHistory(),
});

export default router;
