let isMathjaxConfig = false; // ⽤于标识是否配置
const initMathjaxConfig = () => {
  if (!window.MathJax) {
    return;
  }
  window.MathJax = {
    tex: {
      inlineMath: [
        ["$", "$"],
        ["\\(", "\\)"],
      ], // ⾏内公式选择符
      displayMath: [
        ["$$", "$$"],
        ["\\[", "\\]"],
      ], // 段内公式选择符
      // 下面两个主要是支持渲染某些公式，可以自己了解
      processEnvironments: true,
      processRefs: true,
    },
    options: {
      skipHtmlTags: ["script", "noscript", "style", "textarea", "pre", "code", "a"], // 避开某些标签
      // 跳过mathjax处理的元素的类名，任何元素指定一个类 tex2jax_ignore 将被跳过，多个累=类名'class1|class2'
      ignoreHtmlClass: "tex2jax_ignore",
      processHtmlClass: "tex2jax_process",
    },
    svg: {
      fontCache: "global",
    },
  };
  isMathjaxConfig = true; // 配置完成，改为true
};
const TypeSet = async function (elementId) {
  if (!window.MathJax) {
    return;
  }
  window.MathJax.startup.promise = window.MathJax.startup.promise
    .then(() => {
      return window.MathJax.typesetPromise();
    })
    .catch(err => console.log("Typeset failed: " + err.message));
  return window.MathJax.startup.promise;
};
export default {
  isMathjaxConfig,
  initMathjaxConfig,
  TypeSet,
};
