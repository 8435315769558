import TIM from "tim-js-sdk";
import {initEventListen} from "@/im/tim-events";
import TIMUploadPlugin from "tim-upload-plugin";

const tim = TIM.create({
    SDKAppID: process.env.VUE_APP_IM_SDKAppID
})
// 初始化事件监听
initEventListen(tim)

tim.registerPlugin({'tim-upload-plugin': TIMUploadPlugin})

export default tim
