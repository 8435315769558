import { openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from './icon/record.png'


export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("img", {
    src: _imports_0,
    alt: "",
    title: "历史会话",
    class: "record",
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.recordDis && _ctx.recordDis(...args)))
  }))
}