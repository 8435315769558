import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7f6eaef4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "header" }
const _hoisted_2 = { class: "header-back" }
const _hoisted_3 = { class: "header-title" }
const _hoisted_4 = { class: "header-close" }
const _hoisted_5 = { class: "body" }
const _hoisted_6 = {
  key: 0,
  class: "body-message"
}
const _hoisted_7 = { class: "message" }
const _hoisted_8 = { class: "message-info" }
const _hoisted_9 = { class: "message-cont" }
const _hoisted_10 = ["src"]
const _hoisted_11 = { key: 1 }
const _hoisted_12 = { class: "body-tab" }
const _hoisted_13 = ["onClick"]
const _hoisted_14 = { class: "tab-item-title" }
const _hoisted_15 = { class: "tab-item-count" }
const _hoisted_16 = { class: "body-list" }
const _hoisted_17 = ["src"]
const _hoisted_18 = { class: "name" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.show)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(["read-receipt", [_ctx.isH5 ? 'read-receipt-H5' : '', _ctx.isMenuOpen ? 'read-receipt-menu-open' : '']]),
        ref: "dialog"
      }, [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            (_ctx.isH5)
              ? (_openBlock(), _createElementBlock("i", {
                  key: 0,
                  class: "icon icon-back",
                  onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleShow && _ctx.toggleShow(...args)))
                }))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("span", null, _toDisplayString(_ctx.$t('TUIChat.消息详情')), 1)
          ]),
          _createElementVNode("div", _hoisted_4, [
            (!_ctx.isH5)
              ? (_openBlock(), _createElementBlock("i", {
                  key: 0,
                  class: "icon icon-close",
                  onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.toggleShow && _ctx.toggleShow(...args)))
                }))
              : _createCommentVNode("", true)
          ])
        ]),
        _createElementVNode("div", _hoisted_5, [
          (_ctx.isH5)
            ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                _createElementVNode("div", _hoisted_7, [
                  _createElementVNode("div", _hoisted_8, [
                    _createElementVNode("span", null, _toDisplayString(_ctx.message.from), 1),
                    _createElementVNode("span", null, _toDisplayString(_ctx.caculateTimeago(_ctx.message.time * 1000)), 1)
                  ]),
                  _createElementVNode("div", _hoisted_9, [
                    (_ctx.messageInfo.isImg)
                      ? (_openBlock(), _createElementBlock("img", {
                          key: 0,
                          class: "message-cont-img",
                          src: _ctx.messageInfo.content
                        }, null, 8, _hoisted_10))
                      : (_openBlock(), _createElementBlock("p", _hoisted_11, _toDisplayString(_ctx.messageInfo.content), 1))
                  ])
                ])
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_12, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.readReceiptList, (val, key) => {
              return (_openBlock(), _createElementBlock(_Fragment, null, [
                (val.show)
                  ? (_openBlock(), _createElementBlock("div", {
                      class: _normalizeClass(["tab-item", key === _ctx.showListNow && 'tab-item-now']),
                      key: key,
                      onClick: ($event: any) => (_ctx.showListNow = key)
                    }, [
                      _createElementVNode("div", _hoisted_14, _toDisplayString(val?.label), 1),
                      _createElementVNode("div", _hoisted_15, _toDisplayString(val?.count), 1)
                    ], 10, _hoisted_13))
                  : _createCommentVNode("", true)
              ], 64))
            }), 256))
          ]),
          _createElementVNode("div", _hoisted_16, [
            _createElementVNode("ul", null, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.readReceiptList[_ctx.showListNow].userList, (item, index) => {
                return (_openBlock(), _createElementBlock("li", {
                  key: index,
                  class: "body-list-item"
                }, [
                  _createElementVNode("img", {
                    class: "avatar",
                    src: item?.avatar || 'https://web.sdk.qcloud.com/component/TUIKit/assets/avatar_21.png',
                    onerror: "this.src='https://web.sdk.qcloud.com/component/TUIKit/assets/avatar_21.png'"
                  }, null, 8, _hoisted_17),
                  _createElementVNode("div", _hoisted_18, _toDisplayString(item?.nick || item?.userID), 1)
                ]))
              }), 128))
            ]),
            (!_ctx.readReceiptList[_ctx.showListNow].isCompleted)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 0,
                  class: "more",
                  onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.getMoreList && _ctx.getMoreList(...args)))
                }, "查看更多"))
              : _createCommentVNode("", true)
          ])
        ])
      ], 2))
    : _createCommentVNode("", true)
}