<script setup lang="ts">
import '../style.css';
import { defineProps, toRefs } from 'vue';
const props = defineProps<{
  volume?: number;
}>();
const { volume } = toRefs(props);
</script>

<template>
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 16" class="design-iconfont">
    <g v-if="volume && volume > 0">
      <path
        d="M8.62462 7.17395C8.90076 7.17395 9.12462 7.38807 9.12462 7.65221C9.12462 7.89434 8.93651 8.09444 8.69246 8.12611L8.62462 8.13047H7.3335C7.05735 8.13047 6.8335 7.91635 6.8335 7.65221C6.8335 7.41009 7.0216 7.20998 7.26565 7.17832L7.3335 7.17395H8.62462Z"
        fill="#43D92B" />
    </g>
    <g v-if="volume && volume > 5">
      <path
        d="M8.62462 5.57971C8.90076 5.57971 9.12462 5.79384 9.12462 6.05797C9.12462 6.3001 8.93651 6.5002 8.69246 6.53187L8.62462 6.53623H7.3335C7.05735 6.53623 6.8335 6.32211 6.8335 6.05797C6.8335 5.81585 7.0216 5.61575 7.26565 5.58408L7.3335 5.57971H8.62462Z"
        fill="#43D92B" />
    </g>
    <g v-if="volume && volume > 10">
      <path
        d="M8.62462 3.98547C8.90076 3.98547 9.12462 4.1996 9.12462 4.46373C9.12462 4.70586 8.93651 4.90596 8.69246 4.93763L8.62462 4.942H7.3335C7.05735 4.942 6.8335 4.72787 6.8335 4.46373C6.8335 4.22161 7.0216 4.02151 7.26565 3.98984L7.3335 3.98547H8.62462Z"
        fill="#43D92B" />
    </g>
    <path fill-rule="evenodd" clip-rule="evenodd"
      d="M11.3301 3.45938C11.2517 1.89175 9.77808 0.658081 8 0.658081C6.17253 0.658081 4.66667 1.96123 4.66667 3.59077V7.72539L4.66994 7.85678C4.7483 9.42442 6.22192 10.6581 8 10.6581C9.82747 10.6581 11.3333 9.35493 11.3333 7.72539V3.59077L11.3301 3.45938ZM6.0035 3.54179C6.06337 2.69234 6.92083 1.99146 8.00016 1.99146C9.11947 1.99146 10.0002 2.74521 10.0002 3.63675V7.67949L9.99682 7.77446C9.93695 8.62391 9.07949 9.32479 8.00016 9.32479C6.88086 9.32479 6.00016 8.57103 6.00016 7.67949V3.63675L6.0035 3.54179ZM8.33333 15.3333C8.51743 15.3333 8.66667 15.1841 8.66667 15V13.2964C11.5402 12.9793 14 10.6655 14 8.27322V7.68092C14 7.49682 13.8508 7.34758 13.6667 7.34758H13C12.8159 7.34758 12.6667 7.49682 12.6667 7.68092V8.27322L12.6634 8.39309C12.5681 10.1524 10.3982 11.9979 8.00396 12C8.00264 12 8.00132 12 8 12C7.99868 12 7.99736 12 7.99604 12C5.54737 11.9979 3.33333 10.0675 3.33333 8.27322L3.33043 7.65652C3.32956 7.47164 3.17839 7.32278 2.99352 7.32477L2.32975 7.33191C2.14706 7.33387 2 7.48252 2 7.66522V8.27322L2.00292 8.4162C2.09825 10.757 4.51708 12.9856 7.33333 13.2964V15C7.33333 15.1841 7.48257 15.3333 7.66667 15.3333H8.33333Z"
      fill="#fff" />
  </svg>
</template>