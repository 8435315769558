<template>
  <div class="app-container">
    <router-view></router-view>
  </div>
</template>

<style lang="scss" scoped>
.app-container {
  height: 100%;
  overflow-y: hidden;
}
</style>

<style>
/* 滚动条轨道样式 */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  /* 设置滚动条宽度 */
}

/* 滚动条滑块样式 */
::-webkit-scrollbar-thumb {
  background-color: #ccc;
  /* 设置滑块背景颜色 */  
  border-radius: 3px;
  /* 设置滑块圆角 */
}

/* 滚动条轨道hover状态样式 */
::-webkit-scrollbar-track:hover {
  /* background-color: #f1f1f1; */
  /* 设置轨道hover状态时的背景颜色 */
}

/* 滚动条滑块hover状态样式 */
::-webkit-scrollbar-thumb:hover {
  background-color: #999;
  /* 设置滑块hover状态时的背景颜色 */
}
</style>