import TIM, { ChatSDK } from "tim-js-sdk";
import { ElMessageBox } from "element-plus";
import { useRouter } from "vue-router";
const router = useRouter();

export const initEventListen = (tim: ChatSDK) => {
  const onSDKReady = (event: any) => {
    console.log("登录成功", event);
    tim
      .getMyProfile()
      .then((resp) => {
        console.log("个人信息", resp.data);
        document.title = resp.data.nick;
      })
      .catch(function (imError) {
        console.warn("getMyProfile error:", imError); // 获取会话列表失败的相关信息
      })
      .finally(() => console.log("个人信息获取结束"));
  };

  const onSDKNotReady = (event: any) => {
    console.log("登录失败", event);
  };

  const onKickedOut = (event: any) => {
    console.log("用户被踢下线", event.data.type);
    ElMessageBox.alert(
      "您的账号在另外一台设备登录,请刷新页面重新登录",
      "提示",
      {
        confirmButtonText: "确定",
        callback: (action: any) => {
          location.reload();
          router.go(0);
        },
      }
    );
    // TIM.TYPES.KICKED_OUT_MULT_ACCOUNT(Web端，同一帐号，多页面登录被踢)
    // TIM.TYPES.KICKED_OUT_MULT_DEVICE(同一帐号，多端登录被踢)
    // TIM.TYPES.KICKED_OUT_USERSIG_EXPIRED(签名过期。使用前需要将SDK版本升级至v2.4.0或以上)
    // TIM.TYPES.KICKED_OUT_REST_API(REST API kick 接口踢出。使用前需要将SDK版本升级至v2.20.0或以上)
  };

  const onProfileUpdated = (event: any) => {
    console.log("资料变更", event.data); // 包含 Profile 对象的数组
  };

  const onUserStatusUpdated = (event: any) => {
    console.log("用户状态发生变化", event.data);
    const userStatusList = event.data;
    userStatusList.forEach(
      (item: { userID: string; statusType: string; customStatus: any }) => {
        console.log(item.userID, item.statusType, item.customStatus);
        // userID - 用户 ID
        // statusType - 用户状态，枚举值及说明如下：
        // TIM.TYPES.USER_STATUS_UNKNOWN - 未知
        // TIM.TYPES.USER_STATUS_ONLINE - 在线
        // TIM.TYPES.USER_STATUS_OFFLINE - 离线
        // TIM.TYPES.USER_STATUS_UNLOGINED - 未登录
        // customStatus - 用户自定义状态
      }
    );
  };

  const onNetStateChange = (event: any) => {
    console.log("网络状态发生变化", event.data.state);
    // v2.5.0 起支持
    // event.data.state 当前网络状态，枚举值及说明如下：
    // TIM.TYPES.NET_STATE_CONNECTED - 已接入网络
    // TIM.TYPES.NET_STATE_CONNECTING - 连接中。很可能遇到网络抖动，SDK 在重试。接入侧可根据此状态提示“当前网络不稳定”或“连接中”
    // TIM.TYPES.NET_STATE_DISCONNECTED - 未接入网络。接入侧可根据此状态提示“当前网络不可用”。SDK 仍会继续重试，若用户网络恢复，SDK 会自动同步消息
  };

  const onFriendGroupListUpdated = (event: any) => {
    console.log("好友列表更新", event.data);
  };

  const onFriendApplicationListUpdated = (event: any) => {
    console.log("新的好友申请", event.data.state);
    // friendApplicationList - 好友申请列表 - [FriendApplication]
    // unreadCount - 好友申请的未读数
    const { friendApplicationList, unreadCount } = event.data;
    // 发送给我的好友申请（即别人申请加我为好友）
    const applicationSentToMe = friendApplicationList.filter(
      (friendApplication: any) =>
        friendApplication.type === TIM.TYPES.SNS_APPLICATION_SENT_TO_ME
    );
    // 我发送出去的好友申请（即我申请加别人为好友）
    const applicationSentByMe = friendApplicationList.filter(
      (friendApplication: any) =>
        friendApplication.type === TIM.TYPES.SNS_APPLICATION_SENT_BY_ME
    );
  };

  const onError = (event: any) => {
    // event.data.code - 错误码
    // event.data.message - 错误信息
    console.log("错误", event.data);
  };

  tim.on(TIM.EVENT.SDK_READY, onSDKReady);
  tim.on(TIM.EVENT.SDK_NOT_READY, onSDKNotReady);
  tim.on(TIM.EVENT.KICKED_OUT, onKickedOut);
  tim.on(TIM.EVENT.PROFILE_UPDATED, onProfileUpdated);
  tim.on(TIM.EVENT.USER_STATUS_UPDATED, onUserStatusUpdated);
  tim.on(TIM.EVENT.NET_STATE_CHANGE, onNetStateChange);
  tim.on(TIM.EVENT.FRIEND_GROUP_LIST_UPDATED, onFriendGroupListUpdated);
  tim.on(
    TIM.EVENT.FRIEND_APPLICATION_LIST_UPDATED,
    onFriendApplicationListUpdated
  );
  tim.on(TIM.EVENT.ERROR, onError);

  // 消息声音提醒
  class NotifyController {
    lastMessageSeqMap: Record<string, number> = {};
    noisePrepared = false;
    soundUrl =
      "https://haoxueji-1305824186.cos.ap-guangzhou.myqcloud.com/audio/doorbell.ogg";

    cas(conversationID: string, seq: number) {
      if (!this.lastMessageSeqMap[conversationID]) {
        this.lastMessageSeqMap[conversationID] = seq;
        return;
      }

      if (this.lastMessageSeqMap[conversationID] < seq) {
        this.lastMessageSeqMap[conversationID] = seq;
        this.noisePrepared =
          !this.isCurrentConversation(conversationID) || !this.isActiveTab();
      }
    }

    isCurrentConversation(conversationID: string) {
      const TUIConversation =
        (window as any)?.TUIKitTUICore?.TUIServer?.TUIConversation || {};
      return (
        TUIConversation.currentStore?.currentConversationID === conversationID
      );
    }

    isActiveTab() {
      return document.visibilityState === "visible";
    }

    makeNoise() {

      if (this.noisePrepared) {
        this.noisePrepared = false;
        const audio = new Audio(this.soundUrl);
        audio.play();
      }
    }
  }
  const notifyController = new NotifyController();
  const onConversationListUpdated = (event: any) => {
    console.log("会话列表更新===", event); // 包含 Conversation 实例的数组
    event.data.forEach((it: any) => {
      notifyController.cas(
        it.conversationID || "",
        it.lastMessage?.lastTime || 0
      );
    });
    notifyController.makeNoise();
  };

  tim.on(TIM.EVENT.CONVERSATION_LIST_UPDATED, onConversationListUpdated);
};
