import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-38e7ca88"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["innerHTML"]
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data.text, (item, index) => {
    return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
      (item.name === 'text')
        ? (_openBlock(), _createElementBlock("span", {
            key: 0,
            class: "text-box",
            innerHTML: _ctx.renderMix(item.text)
          }, null, 8, _hoisted_1))
        : (item.name === 'img')
          ? (_openBlock(), _createElementBlock("img", {
              key: 1,
              class: "text-img",
              src: item.src
            }, null, 8, _hoisted_2))
          : _createCommentVNode("", true)
    ], 64))
  }), 128))
}