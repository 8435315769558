<template>
  <div class="TUI-conversation" :style="conversationHeight.itemHeight">
    <div class="network" v-if="isNetwork">
      <i class="icon icon-error">!</i>
      <p>️{{ $t('TUIConversation.网络异常，请您检查网络设置') }}</p>
    </div>
    <main class="TUI-conversation-list">
      <TUIConversationList :currentID="currentConversationID" :data="conversationData" @handleItem="handleCurrentConversation" :isH5="env.isH5" :displayOnlineStatus="displayOnlineStatus" :userStatusList="userStatusList" />
    </main>
  </div>
</template>
<script lang="ts">
import {
  defineComponent,
  reactive,
  toRefs,
  computed,
  watch,
  defineEmits,
  ref,
} from "vue";
import TUIConversationList from "./components/list";
import { caculateTimeago, isArrayEqual } from "../utils";
import {
  handleAvatar,
  handleName,
  handleShowLastMessage,
  handleAt,
} from "../TUIChat/utils/utils";
import { useStore } from "vuex";

const TUIConversation = defineComponent({
  name: "TUIConversation",

  components: {
    TUIConversationList,
  },
  props: {
    displayOnlineStatus: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["current", "showChatUI", "chatList"],
  setup(props: any, ctx: any) {
    const TUIServer: any = TUIConversation?.TUIServer;
    // const VuexStore = useStore && useStore();
    const data = reactive({
      currentConversationID: "",
      conversationData: {
        list: [],
        handleItemAvator: (item: any) => handleAvatar(item),
        handleItemName: (item: any) => {
          let name = handleName(item);
          // if(VuexStore.state.orderList.indexOf(item.conversationID.substring(3)) >= 0){
          //   name += '(答疑中)';
          // }
          return name;
        },
        handleShowAt: (item: any) => handleAt(item),
        handleShowMessage: (item: any) => handleShowLastMessage(item),
        handleItemTime: (time: number) => {
          if (time > 0) {
            return caculateTimeago(time * 1000);
          }
          return "";
        },
      },
      userIDList: new Set(),
      netWork: "",
      env: TUIServer.TUICore.TUIEnv,
      displayOnlineStatus: false,
      userStatusList:
        TUIServer.TUICore.TUIServer.TUIContact?.currentStore?.userStatusList,
    });

    TUIServer.bind(data);

    TUIConversationList.TUIServer = TUIServer;

    watch(
      () => data.currentConversationID,
      (newVal: any, oldVal: any) => {
        ctx.emit("current", newVal);
        ctx.emit("chatList", {
          currentConversationID: data.currentConversationID || "",
        });
      },
      {
        deep: true,
      }
    );

    watch(
      () => props.displayOnlineStatus,
      async (newVal: any, oldVal: any) => {
        if (newVal === oldVal) return;
        data.displayOnlineStatus = newVal;
        TUIServer.TUICore.TUIServer.TUIContact.handleUserStatus(
          data.displayOnlineStatus,
          [...data.userIDList]
        );
        data.userStatusList =
          TUIServer.TUICore.TUIServer.TUIContact?.currentStore?.userStatusList;
      }
    );

    watch(
      () => [...data.userIDList],
      async (newVal: any, oldVal: any) => {
        if (isArrayEqual(newVal, oldVal)) return;
        TUIServer.TUICore.TUIServer.TUIContact.handleUserStatus(
          data.displayOnlineStatus,
          [...data.userIDList]
        );
      },
      {
        deep: true,
      }
    );

    const isNetwork = computed(() => {
      const disconnected =
        data.netWork === TUIServer.TUICore.TIM.TYPES.NET_STATE_DISCONNECTED;
      const connecting =
        data.netWork === TUIServer.TUICore.TIM.TYPES.NET_STATE_CONNECTING;
      return disconnected || connecting;
    });

    let conversationHeight = ref({
      itemHeight: "height:0px",
    });
    // 监听会话列表长度变化
    watch(
      () => data.conversationData.list,
      (newVal: any, oldVal: any) => {
        // console.log("newVal===", newVal)
        if (newVal) {
          conversationHeight.value["itemHeight"] =
            "height:" + 92 * newVal.length + "px";
          ctx.emit("chatList", {
            list: data.conversationData.list || [],
          });
        }
      }
    );

    const handleCurrentConversation = (value: any) => {
      console.log("value===2", value);
      ctx.emit("showChatUI", true);
      TUIServer.handleCurrentConversation(value);
    };

    return {
      ...toRefs(data),
      handleCurrentConversation,
      isNetwork,
      conversationHeight,
    };
  },
});
export default TUIConversation;
</script>

<style lang="scss" scoped src="./style/index.scss"></style>
