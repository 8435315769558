<template>
  <span class="upload-btn icon icon-image">
      <input title="图片" v-if="!isMute" type="file" data-type="image" accept="image/*" @change="sendUploadMessage" />
      <slot />
  </span>
</template>

<script lang="ts">
import TUIAegis from '../../../../../utils/TUIAegis';
import { defineComponent, reactive, toRefs, watchEffect, watch } from 'vue';
import { handleErrorPrompts } from '../../../utils';
import { getImageFileFromUrl } from '../../utils/utils';

const Image = defineComponent({
  props: {
    show: {
      type: Boolean,
      default: () => false,
    },
    isMute: {
      type: Boolean,
      default: () => false,
    },
    isH5: {
      type: Boolean,
      default: () => false,
    },
    speechImg: {
      type: String,
      default: "",
    },
  },
  emits: ["resetProps"],
  setup(props:any, ctx:any) {
    const data = reactive({
      isMute: false,
    });
  
    watchEffect(() => {
      data.isMute = props.isMute;
    });
    watch(
      () => props.speechImg,
      (newVal: string, oldVal: string) => {
        if (!newVal || newVal === oldVal) return

        getImageFileFromUrl(props.speechImg, "fileName")
          .then( async (response)=> {
            const imageFile  = response
            await Image.TUIServer.sendImageMessage(imageFile);
            TUIAegis.getInstance().reportEvent({
              name: 'messageType',
              ext1: 'typeImage',
            });
            ctx.emit("resetProps", {
              type: 'speechImg',
              value: ''
            });

          }).catch((error)=>{
            ctx.emit("resetProps", {
              type: 'speechImg',
              value: ''
            });
            handleErrorPrompts(error, props);

          })
      }
    );

    // 发送需要上传的消息：图片
    const sendUploadMessage = async (e:any) => {
      if (e.target.files.length > 0) {
        try {
          await Image.TUIServer.sendImageMessage(e.target);
          TUIAegis.getInstance().reportEvent({
            name: 'messageType',
            ext1: 'typeImage',
          });
        } catch (error) {
          handleErrorPrompts(error, props);
        }
      }
      e.target.value = '';
    };

    return {
      ...toRefs(data),
      sendUploadMessage,
    };
  },
});
export default Image;
</script>

<style lang="scss" scoped>
.upload-btn {
  position: relative;
  input {
    position: absolute;
    cursor: pointer;
    left: 0;
    top: 0;
    opacity: 0;
    width: 100%;
    height: 100%;
  }
}
</style>
