<template>
  <img
    src="./icon/record.png"
    alt=""
    title="历史会话"
    class="record"
    @click="recordDis"
  />
</template>

<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({
  emits: ["recordDis"],
  setup(props, { emit }) {
    const recordDis = () => {
      emit("recordDis");
    };
    return {
      recordDis,
    };
  },
});
</script>

<style scoped>
.record {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 18px;
  margin: 0px 12px 0;
  vertical-align: baseline;
  cursor: pointer;
}
</style>
