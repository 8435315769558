export default {
  //时间戳格式化
  getFormatTime(dateTime, flag) {
    if (!dateTime) {
      return "";
    }

    const date = new Date(dateTime);
    const YY = date.getFullYear();
    const MM = String(date.getMonth() + 1).padStart(2, "0");
    const DD = String(date.getDate()).padStart(2, "0");

    if (flag) {
      const hh = String(date.getHours()).padStart(2, "0");
      const mm = String(date.getMinutes()).padStart(2, "0");
      const ss = String(date.getSeconds()).padStart(2, "0");
      return `${YY}-${MM}-${DD} ${hh}:${mm}:${ss}`;
    } else {
      return `${YY}-${MM}-${DD}`;
    }
  },
  //学生年级格式化
  gradeFormat(grade) {
    const gradeMap = {
      3: "三年级",
      4: "四年级",
      5: "五年级",
      6: "六年级",
      7: "七年级",
      8: "八年级",
      9: "九年级",
      11: "高一",
      12: "高二",
      13: "高三",
    };

    return gradeMap[grade] || "";
  },
};
